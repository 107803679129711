import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ViewRoute } from "view/route";
import { ViewRouteEdit } from "view/route.edit";
import { ViewMappack } from "view/mappack";
import { ViewMappackEdit } from "view/mappack.edit";
import { ViewArea } from "view/area";
import { ViewAreaEdit } from "view/area.edit";
import { ViewMarker } from "view/marker";
import { ViewMarkerEdit } from "view/marker.edit";
import "./App.css";
import { useEffect, useState } from "react";
import { AppMenu, getAppMenu } from "components/core-sub/app.menu";
import CoreProvider from "components/core-sub/context";
import { app } from "components/core-sub/Controller/firebase";
import { MapContainer } from "components/map.container";

function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/area/edit/:id" element={<ViewAreaEdit />} />
        <Route path="/route/edit/:id" element={<ViewRouteEdit />} />
        <Route path="/marker/edit/:id" element={<ViewMarkerEdit />} />
        <Route path="/mappack/edit/:id" element={<ViewMappackEdit />} />
        <Route
          path="/*"
          element={
            <MapContainer>
              <Routes>
                <Route path="/area" element={<ViewArea />} />
                <Route path="/route" element={<ViewRoute />} />
                <Route path="/marker" element={<ViewMarker />} />
                <Route path="/mappack" element={<ViewMappack />} />
                <Route path="/" element={<ViewMappack />} />
              </Routes>
            </MapContainer>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  const [state, setState] = useState<{
    appmenu: AppMenu[];
  }>({
    appmenu: [],
  });

  useEffect(() => {
    getAppMenu(app).then((value) =>
      setState((s) => ({ ...s, appmenu: value }))
    );
  }, []);

  return (
    <CoreProvider
      firebaseApp={app}
      logo={process.env.REACT_APP_LOGO}
      appMenu={state.appmenu}
      sitename="MAPS"
    >
      <Routing />
    </CoreProvider>
  );
}

export default App;
