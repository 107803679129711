import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  styled,
} from "@mui/material";
import { Marker, Polygon, Polyline } from "@react-google-maps/api";
import ActionIcon from "components/core-sub/ActionIcon";
import { useAlerts } from "components/core-sub/Alerts";
import { BackLink } from "components/core-sub/BackLink";
import { useCore } from "components/core-sub/context";
import { KuiActionIcon } from "components/core-sub/KuiActionIcon";
import MainContainer from "components/core-sub/MainContainer";
import { getMarkerIcon, GoogleMaps, MapIcon, MarkerCatType } from "components/core-sub/Maps";
import { SaveButton } from "components/core-sub/SaveButton";
import { TitleDebounce } from "components/core-sub/TitleDebounce";
import { VisibilitySelect } from "components/core-sub/Visibility";
import { MapController, MapDocument } from "controllers/mappack";
import { useCallback, useEffect, useState } from "react";
import update from "react-addons-update";
import { useParams } from "react-router-dom";
import { MappackSelectItem } from "./select.item";

const MapRoot = styled(Box)(() => ({
  width: "100%",
  height: `calc(100vh - 65px)`,
}));

export const ViewMappackEdit = () => {
  const { user, t } = useCore();
  const { id } = useParams();
  const [state, setState] = useState<{
    loading: boolean;
    restrict: boolean;
    data: MapDocument | null;
  }>({
    loading: true,
    restrict: true,
    data: null,
  });
  const [opens, setOpens] = useState<Record<string, boolean>>({});
  const [control, setControl] = useState<MapController>();
  const { addAlert } = useAlerts();
  const [maps, setMaps] = useState<Record<string, MapDocument>>({});
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (user.loading === false && id) {
      if (user.data) {
        const control = new MapController(user.data);
        setControl(control);
        control.getMappack(id).then((data) => {
          if (data.user === user.data?.uid) {
            setState((s) => ({ ...s, loading: false, restrict: false, data }));
          } else {
            setState((s) => ({ ...s, loading: false, restrict: true }));
          }
        });
      } else {
        setState({ loading: false, restrict: true, data: null });
      }
    }
  }, [id, user]);

  const handleFitBounds = useCallback(
    (data: Record<string, MapDocument>) => {
      const bounds = new window.google.maps.LatLngBounds();
      Object.values(data).forEach((doc) => {
        if (doc.latLng) {
          bounds.extend(doc.latLng);
        } else if (doc.latLngs) {
          doc.latLngs.forEach((latLng) => bounds.extend(latLng));
        }
      });
      map?.fitBounds(bounds);
    },
    [map]
  );

  const handleOpen = (key: string, value: boolean) => () =>
    setOpens((o) => ({ ...o, [key]: value }));
  const handleChangeTitle = (title: string) =>
    setState((s) => update(s, { data: { title: { $set: title } } }));
  const handleUpdate = (key: string, value: any) => {
    setState((s) => update(s, { data: { [key]: { $set: value } } }));
  };
  const handleSave = async () => {
    if (id && state.data) {
      await control?.save(id, state.data);
      addAlert({ label: t("Saved") });
    }
  };
  const handleRemove = (index: number) => () => {
    setState((s) => update(s, { data: { maps: { $splice: [[index, 1]] } } }));
  };

  useEffect(() => {
    if (state.data?.maps && control) {
      control.getFromIds(state.data.maps).then((data) => {
        setMaps(data);
        handleFitBounds(data);
      });
    }
  }, [state.data?.maps, control, handleFitBounds]);

  return (
    <MainContainer
      loading={state.loading}
      restrict={state.restrict}
      dense
      signInOnly
      sidebar={
        state.restrict === false && (
          <>
            <BackLink to="/mappack" divider />
            <SaveButton onSave={handleSave} />
            <TitleDebounce
              value={state.data?.title}
              onChange={handleChangeTitle}
            />
            <VisibilitySelect
              value={state.data?.visibility}
              onChange={(value) =>
                state.data &&
                setState((s) =>
                  update(s, { data: { visibility: { $set: value } } })
                )
              }
            />
            <List>
              <ListItem divider>
                <ListItemText
                  primary="List"
                  primaryTypographyProps={{
                    variant: "caption",
                    color: "textSecondary",
                  }}
                />
                <ListItemSecondaryAction>
                  <KuiActionIcon tx="add" onClick={handleOpen("add", true)} />
                  <ActionIcon
                    iconProps={{ size: "xs" }}
                    icon={"arrows-minimize"}
                    onClick={() => handleFitBounds(maps)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              {state.data?.maps?.map(
                (id, index) =>
                  maps?.[id] && (
                    <ListItemButton divider key={id}>
                      <ListItemIcon>
                        <MapIcon
                          size="small"
                          type={maps[id].type}
                          cat={maps[id].cat}
                          color={maps[id].color}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={maps?.[id]?.title}
                        primaryTypographyProps={{ variant: "body2" }}
                      />
                      <ListItemSecondaryAction>
                        <KuiActionIcon
                          tx="remove"
                          onClick={handleRemove(index)}
                        />
                      </ListItemSecondaryAction>
                    </ListItemButton>
                  )
              )}
            </List>
          </>
        )
      }
    >
      <MapRoot>
        <GoogleMaps onLoad={setMap}>
          {state.data?.maps?.map((id) => {
            const item = maps[id];
            if (item) {
              switch (item.type) {
                case "marker":
                  return item.latLng ? (
                    <Marker
                      position={item.latLng}
                      key={id}
                      icon={getMarkerIcon(item.cat as MarkerCatType)}
                    />
                  ) : null;
                case "route":
                  return item.latLngs ? (
                    <Polyline
                      path={item.latLngs}
                      options={{ strokeColor: item.color, strokeWeight: 4 }}
                      key={id}
                    />
                  ) : null;
                case "area":
                  return item.latLngs ? (
                    <Polygon
                      paths={item.latLngs}
                      options={{
                        strokeColor: item.color,
                        fillColor: item.color,
                        fillOpacity: 0.4,
                        strokeWeight: 4,
                      }}
                      key={id}
                    />
                  ) : null;
                default:
                  return null;
              }
            } else {
              return null;
            }
          })}
        </GoogleMaps>
      </MapRoot>
      <MappackSelectItem
        selection={state.data?.maps}
        open={Boolean(opens.add)}
        onClose={handleOpen("add", false)}
        onConfirm={(ids) => handleUpdate("maps", ids)}
      />
    </MainContainer>
  );
};
