import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  List,
  ListItemButton as LIB,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const ListItemButton = styled(LIB)(({ theme }) => ({
  "&.Mui-selected": {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  "& .MuiListItemIcon-root": {
    color: "inherit",
  },
}));

export type tabKey = "mappack" | "marker" | "route" | "area";
const links: (Record<"label" | "to", string> & {
  key: tabKey;
  icon: IconName;
})[] = [
  { label: "MapPack", to: "mappack", key: "mappack", icon: "route" },
  { label: "Marker", to: "marker", key: "marker", icon: "map-marker-alt" },
  { label: "Route", to: "route", key: "route", icon: "code-branch" },
  { label: "Area", to: "area", key: "area", icon: "draw-polygon" },
];

export interface MainSidebarProps {
  selected?: tabKey;
}
export const MainSidebar = (props: MainSidebarProps) => {
  const selected = props.selected || "mappack";
  const nav = useNavigate();

  return (
    <div>
      <List>
        {links.map((item) => (
          <ListItemButton
            divider
            key={item.key}
            selected={selected === item.key}
            onClick={() => nav(`/${item.to}`)}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={["far", item.icon]} />
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItemButton>
        ))}
      </List>
    </div>
  );
};
