import { cleanObject } from "components/core-sub/func";
import { addDoc, onSnapshot, query, serverTimestamp, where, Timestamp, updateDoc, deleteDoc, getDoc } from "firebase/firestore";
import { SkeletonController } from "./skeleton.controller";

export type MapType = "mappack" | "marker" | "route" | "area";
export type MapAddress = Record<string, string>
export type MapPosition = {lat:number,lng:number}
export type MapDocument = {
  id?: string
  address?: MapAddress
  cat?: string
  color?: string
  datecreate?: Timestamp
  datemodified?: Timestamp
  latLng?: MapPosition
  latLngs?: MapPosition[]
  type: MapType
  title: string
  user?: string
  maps?: string[]
  visibility?: 'public' | 'private' | 'trash'
}
export type MapDocumentOptions = {
  [key in keyof MapDocument]?: MapDocument[key]
}

export class MapController extends SkeletonController {
  async getOne(id:string):Promise<MapDocument | null>{
    const data = (await getDoc(this.doc('documents', id))).data()
    return data?.user === this.user.uid ? data as MapDocument : null
  }
  async getFromIds(ids:string[]):Promise<Record<string, MapDocument>>{
    const docs = (await Promise.all(ids.map(async id => ({ [id]: await this.getOne(id) }))))
    return Object.assign({}, ...docs)
  }
  watchList = <T extends unknown>(
    type: MapType,
    callback: (docs: T[]) => void
  ) => {
    return onSnapshot(
      query(
        this.collection("documents"),
        where("user", "==", this.user.uid),
        where("type", "==", type),
      ),
      (snapshot) => {
        const docs = snapshot.docs.map(this.toDoc<T>)
        callback(docs);
      }
    );
  };
  watchAll = <T extends unknown>(callback: (docs: T[]) => void) => {
    return onSnapshot(
      query(
        this.collection("documents"),
        where("user", "==", this.user.uid),
        where("type", "in", ["marker", "route", "area"])
      ),
      (snapshot) => {
        const docs = snapshot.docs.map(this.toDoc<T>);
        callback(docs);
      }
    );
  };
  add = async (data:MapDocument) => {
    const newData = {
      ...cleanObject(data),
      datecreate: serverTimestamp(),
      datemodified: serverTimestamp(),
      user: this.user.uid,
    };
    const newDoc = await addDoc(this.collection("documents"), newData)
    return newDoc.id
  };
  update = async (id:string, { datecreate, user, ...data }: MapDocumentOptions) => {
    const newData = {
      ...JSON.parse(JSON.stringify(data)),
      datemodified: serverTimestamp(),
    };
    await updateDoc(this.doc("documents", id), newData)
  };
  remove = async (id:string) => await deleteDoc(this.doc("documents", id))
  save = async (id:string,{user, datecreate, ...data}:MapDocument):Promise<void> => {
    await updateDoc(this.doc("documents", id), {
      ...cleanObject(data),
      datemodified: serverTimestamp(),
    })
  }
  getMappack = async (id:string):Promise<MapDocument> => {
    const snap = await getDoc(this.doc('documents', id))
    return snap.data() as MapDocument;
  };
  saveMappack = async (id?:string, data?:MapDocument) => {
    if(!id && data){
      const snap = await addDoc(this.collection("documents"), {
        title: data.title,
        maps: data.maps,
        datecreate: serverTimestamp(),
        datemodified: serverTimestamp(),
        type: "mappack",
        user: this.user.uid,
      })
      return snap.id;
    } else if(id && data) {
      await updateDoc(
        this.doc("documents", id),
        {
          title: data.title,
          maps: data.maps,
          datemodified: serverTimestamp(),
        }
      )
      return id;
    }
  };
  showMappack = async (maps:string[]):Promise<MapDocument[]> => {
    const watch = maps.map(async (id):Promise<MapDocument> => {
      const snap = await getDoc(this.doc("documents", id))
      return { ...snap.data(), id: snap.id } as MapDocument;
    });
    return await Promise.all(watch);
  };
}

export class MapPack {
  id?: string
  address?: MapAddress
  cat?: string
  color?: string
  type: MapType = "mappack"
  title?: string
  user?: string
  maps?: string[]
  visibility?: 'public' | 'private' | 'trash'

  constructor(data:MapDocumentOptions & { title?: string }){
    if(data.id){ this.id = data.id }
    if(data.address){ this.address = data.address }
    if(data.cat){ this.cat = data.cat }
    if(data.color){ this.color = data.color }
    if(data.title){ this.title = data.title }
    if(data.user){ this.user = data.user }
    if(data.maps){ this.maps = data.maps }
    if(data.visibility){ this.visibility = data.visibility }
  }
}