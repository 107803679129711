import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import update from "react-addons-update";
import {
  Box,
  Button,
  ButtonProps,
  ListItem,
  SelectChangeEvent,
  Stack,
  styled,
  TextField,
} from "@mui/material";
import { RouteCatSelect } from "./select";
import { Marker, Polyline } from "@react-google-maps/api";
import { useAlerts } from "components/core-sub/Alerts";
import { BackLink } from "components/core-sub/BackLink";
import { useCore } from "components/core-sub/context";
import MainContainer from "components/core-sub/MainContainer";
import { GoogleMaps } from "components/core-sub/Maps";
import { SaveButton } from "components/core-sub/SaveButton";
import { TitleDebounce } from "components/core-sub/TitleDebounce";
import { VisibilitySelect } from "components/core-sub/Visibility";
import { getRouteIcon } from "./icons";
import { PickIcon, PickIconName } from "components/core-sub/PickIcon";
import { Map } from "components/core-sub/Controller/map";

const MapRoot = styled(Box)(() => ({
  width: "100%",
  height: `calc(100vh - 65px)`,
}));

export interface stateTypes {
  loading: boolean;
  restrict: boolean;
  data: Map;
}

export const ButtonIcon = ({
  icon,
  ...props
}: Omit<ButtonProps, "startIcon"> & { icon: PickIconName }) => {
  return (
    <Button
      fullWidth
      variant="outlined"
      color="neutral"
      {...props}
      startIcon={<PickIcon icon={icon} />}
    />
  );
};

export const ViewRouteEdit = () => {
  const { id } = useParams();
  const { user, t } = useCore();
  const { addAlert } = useAlerts();
  const [state, setState] = useState<stateTypes>({
    loading: true,
    restrict: true,
    data: new Map({}),
  });
  const [store, setStore] = useState<{
    select: number;
    init: boolean;
    map?: google.maps.Map;
  }>({
    select: -1,
    init: false,
  });

  const handleSave = async () => {
    await state.data?.save();
    addAlert({ label: t("Saved") });
  };
  const handleUnselect = () =>
    setStore((s) => update(s, { select: { $set: -1 } }));
  const handleChangeTitle = (value: string) =>
    setState((s) => ({ ...s, data: s.data?.set("title", value) }));
  const handleChangeColor = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) =>
    setState((s) => ({ ...s, data: s.data?.set("color", value) }));
  const handleChangeCat = ({ target: { value } }: SelectChangeEvent<unknown>) =>
    setState((s) => ({ ...s, data: s.data?.set("cat", String(value)) }));
  const handleDragend = (index: number) => (e: google.maps.MapMouseEvent) => {
    const latLng = e?.latLng?.toJSON();
    if (latLng) {
      setState((s) => ({ ...s, data: s.data?.setLatLngs(index, latLng) }));
    }
  };
  const handleMarkerClick = (index: number) => () =>
    setStore((s) => update(s, { select: { $set: index } }));
  const handleLineClick = (index: number) => (e: google.maps.MapMouseEvent) => {
    const latLng = e.latLng?.toJSON();
    if (latLng) {
      setState((s) => ({ ...s, data: s.data?.insertLatLngs(index, latLng) }));
      handleUnselect();
    }
  };
  const handleRemoveLast = () =>
    setState((s) => ({ ...s, data: s.data?.removeLastLatLngs() }));
  const handleRemoveSelect = () => {
    if (store.select > -1) {
      setState((s) => ({ ...s, data: s.data?.removeLatLngs(store.select) }));
    }
    handleUnselect();
  };
  const handleClosePath = () =>
    setState((s) => ({ ...s, data: s.data?.closePathLatLngs() }));
  const handleReverse = () =>
    setState((s) => ({ ...s, data: s.data?.reverseLatLngs() }));
  const handleMapClick = (e: google.maps.MapMouseEvent) => {
    const latLng = e.latLng?.toJSON();
    if (latLng) {
      setState((s) => ({ ...s, data: s.data?.appendLatLngs(latLng) }));
    }
  };

  useEffect(() => {
    if (user.loading === false && user.data && id) {
      Map.getOne(id).then((data) => {
        if (data) {
          setState((s) => ({ ...s, data, loading: false, restrict: false }));
        } else {
          setState((s) => ({ ...s, loading: false, restrict: true }));
        }
      });
    } else {
      setState({
        loading: true,
        restrict: true,
        data: new Map({}),
      });
    }
  }, [user, id, store.map]);

  useEffect(() => {
    if (store.init === false && store.map && state.data.latLngs?.length) {
      setStore((s) => ({ ...s, init: true }));
      const bounds = new window.google.maps.LatLngBounds();
      state.data.latLngs.forEach((latLng) => bounds.extend(latLng));
      store.map.fitBounds(bounds);
    }
  }, [store.init, store.map, state.data.latLngs]);

  return (
    <MainContainer
      dense
      signInOnly
      loading={state.loading}
      restrict={state.restrict}
      sidebar={
        <>
          <BackLink divider to={`/route`} />
          <SaveButton onSave={handleSave} />
          <TitleDebounce
            value={state.data.title}
            onChange={handleChangeTitle}
          />
          <VisibilitySelect
            value={state.data.visibility}
            onChange={(value) => {
              state.data.visibility = value;
              setState((s) => ({ ...s, data: state.data }));
            }}
          />
          <ListItem divider>
            <Stack sx={{ flex: 1, py: 1 }} spacing={2}>
              <RouteCatSelect
                value={state.data.cat}
                onChange={handleChangeCat}
              />
              <TextField
                fullWidth
                type="color"
                label="Color"
                value={state.data.color}
                onChange={handleChangeColor}
              />
              <ButtonIcon icon={"exchange-alt"} onClick={handleReverse}>
                {t("Reverse")}
              </ButtonIcon>
              {state.data.canClosePathLatLngs() && (
                <ButtonIcon icon={"sync-alt"} onClick={handleClosePath}>
                  {t("Close Path")}
                </ButtonIcon>
              )}
              <ButtonIcon
                color="error"
                icon={"trash"}
                onClick={handleRemoveLast}
              >
                {t("Remove Last Point")}
              </ButtonIcon>
            </Stack>
          </ListItem>
          {store.select > -1 && (
            <ListItem divider>
              <Stack spacing={1} sx={{ flex: 1 }}>
                <ButtonIcon
                  icon={"trash"}
                  color="error"
                  onClick={handleRemoveSelect}
                >
                  Position #{store.select + 1}
                </ButtonIcon>
                <ButtonIcon icon="xmark" onClick={handleUnselect}>
                  {t("Deselect")}
                </ButtonIcon>
              </Stack>
            </ListItem>
          )}
        </>
      }
    >
      <MapRoot>
        <GoogleMaps
          onLoad={(map) => setStore((s) => ({ ...s, map }))}
          onClick={handleMapClick}
        >
          {state.data.latLngs?.map((latLng, index) => (
            <Marker
              key={index}
              position={latLng}
              draggable={true}
              onDragEnd={handleDragend(index)}
              onClick={handleMarkerClick(index)}
              icon={getRouteIcon(
                state.data.color,
                index === 0 ? "medium" : "small"
              )}
            />
          ))}
          {(state.data.latLngs?.length ?? 0) > 1 &&
            state.data.latLngs?.map((latLng, index, latLngs) => {
              return index > 0 ? (
                <Polyline
                  path={[latLng, latLngs[index - 1]]}
                  key={index}
                  options={{
                    strokeColor: state.data.color,
                    strokeOpacity: 0.25 + (0.75 / index),
                    strokeWeight: 4,
                  }}
                  onClick={handleLineClick(index)}
                />
              ) : null;
            })}
        </GoogleMaps>
      </MapRoot>
    </MainContainer>
  );
};
