import { Box, MenuItem, Select, SelectChangeEvent, SelectProps } from "@mui/material";
import { MarkerCat } from "components/core-sub/Maps/maps.icon"
import { PickIcon } from "components/core-sub/PickIcon";

export interface MarkerSelectCateProps {
  value: string;
  onClose?: () => void;
  onChange?: (event:SelectChangeEvent<unknown>) => void;
  selectProps?: Omit<SelectProps,"children">;
}

export const MarkerCatSelect = (props: MarkerSelectCateProps) => {
  return (
    <Select
      variant="outlined"
      displayEmpty
      value={props.value}
      onChange={props.onChange}
      {...props.selectProps}
    >
      <MenuItem value="">-- เลือกประเภท --</MenuItem>
      {MarkerCat.map((icon) => (
        <MenuItem key={icon.id} value={icon.id}>
          <Box
            display="inline-block"
            style={{
              width: 36,
              color: icon.color,
            }}
          >
            <PickIcon icon={icon.icon} />
          </Box>
          {icon.label}
        </MenuItem>
      ))}
    </Select>
  );
};