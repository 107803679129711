import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { DataGrid } from "components/core-sub/DataGrid";
import { useCore } from "components/core-sub/context";
import { usePopup } from "components/core-sub/Popup";
import {
  VisibilityTabs,
  VisibilityTabsValue,
} from "components/core-sub/VisibilityTabs";
import { ContentHeader } from "components/core-sub/ContentHeader";
import { KuiButton } from "components/core-sub/KuiButton";
import { KuiActionIcon } from "components/core-sub/KuiActionIcon";
import { MapIcon } from "components/core-sub/Maps";
import { Map } from "components/core-sub/Controller/map";
import { useMapctn } from "components/map.container";
import { DialogMaps } from "components/core-sub/DialogMaps";
import { Link } from "@mui/material";

export interface stateTypes {
  loading: boolean;
  docs: Map[];
  view: null | { name: string; docs: Map[] };
}

export const ViewRoute = () => {
  const { setTab: st } = useMapctn();
  const { user, t } = useCore();
  const { Popup } = usePopup();
  const navigate = useNavigate();
  const [tab, setTab] = useState<VisibilityTabsValue>("public");
  const [state, setState] = useState<stateTypes>({
    loading: true,
    docs: [],
    view: null,
  });

  const handleAdd = async () => {
    Popup.prompt({
      title: "Create Route",
      text: t("Title"),
      icon: "plus-circle",
      onConfirm: async (value) => {
        if (value && user.data) {
          const doc = await Map.add(user.data, value, "route");
          setTab("private");
          Popup.confirm({
            title: t("Added"),
            text: t("Ready To Edit", { name: value }),
            icon: "info-circle",
            onConfirm: () => {
              navigate(`/route/edit/${doc.id}`);
            },
          });
        }
      },
    });
  };
  const handleRestore = (doc: Map) => () => doc.update("visibility", "private");
  const handleTrash = (doc: Map) => () => {
    Popup.remove({
      title: t("Remove"),
      text: t("Do You Want To Remove $Name", { name: doc.title }),
      icon: "trash",
      onConfirm: async () => {
        await doc.update("visibility", "trash");
        Popup.alert({
          title: t("Removed"),
          text: t("$Name Has Removed", { name: doc.title }),
          icon: "check-circle",
        });
      },
    });
  };
  const handleRemove = (doc: Map) => () => {
    Popup.remove({
      title: t("Remove"),
      text: t("Do You Want To Remove $Name", { name: doc.title }),
      icon: "trash",
      onConfirm: async () => {
        await doc.remove();
        Popup.alert({
          title: t("Removed"),
          text: t("$Name Has Removed", { name: doc.title }),
          icon: "check-circle",
        });
      },
    });
  };

  useEffect(() => {
    st("route");
    if (user.loading === false && user.data) {
      return Map.watchMy(
        user.data,
        (docs) => setState((s) => ({ ...s, loading: false, docs })),
        ["route"]
      );
    }
  }, [user, st]);

  return (
    <>
      <ContentHeader
        label="My Route"
        breadcrumbs={[{ label: t("Home") }, { label: "My Route" }]}
        actions={<KuiButton tx="add" onClick={handleAdd} />}
      />
      <VisibilityTabs
        value={tab}
        onChange={setTab}
        count={{
          public: state.docs.filter((d) => d.visibility === "public").length,
          private: state.docs.filter((d) => d.visibility === "private").length,
          trash: state.docs.filter((d) => d.visibility === "trash").length,
        }}
      />
      <DataGrid
        loading={state.loading}
        rows={state.docs.filter((d) => d.visibility === tab)}
        columns={[
          {
            field: "action",
            headerName: " ",
            renderCell: ({ row }) =>
              row.visibility === "trash" ? (
                <>
                  <KuiActionIcon tx="restore" onClick={handleRestore(row)} />
                  <KuiActionIcon tx="remove" onClick={handleRemove(row)} />
                </>
              ) : (
                <>
                  <KuiActionIcon
                    tx="edit"
                    onClick={() => navigate(`/route/edit/${row.id}`)}
                  />
                  <KuiActionIcon tx="remove" onClick={handleTrash(row)} />
                </>
              ),
            width: 64,
            align: "center",
            filterable: false,
            sortable: false,
          },
          {
            field: "icon",
            width: 64,
            align: "center",
            filterable: false,
            sortable: false,
            headerName: " ",
            renderCell: ({ row }) => (
              <MapIcon type={row.type} cat={row.cat} color={row.color} />
            ),
          },
          {
            field: "title",
            headerName: t("Title"),
            width: 240,
            renderCell: ({ row }) => (
              <Link
                onClick={() =>
                  setState((s) => ({
                    ...s,
                    view: { name: row.title, docs: [row] },
                  }))
                }
                sx={{ cursor: "pointer" }}
              >
                {row.title}
              </Link>
            ),
          },
          {
            field: "datemodified",
            headerName: t("Date"),
            width: 240,
            valueFormatter: ({ value }) => moment(value).format("L LT"),
          },
        ]}
        disableSelectionOnClick
      />
      <DialogMaps
        open={Boolean(state.view)}
        onClose={() => setState((s) => ({ ...s, view: null }))}
        icon="route"
        maps={state.view?.docs ?? []}
        title={state.view?.name}
      />
    </>
  );
};
