import { Box, BoxProps } from "@mui/material";
import { Container } from "components/core-sub/Container";
import MainContainer from "components/core-sub/MainContainer";
import { MainSidebar, tabKey } from "components/main.sidebar";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";

type MapContextType = {
  tab: tabKey;
  setTab: Dispatch<SetStateAction<tabKey>>;
};
const MapContext = createContext<MapContextType>({
  tab: "mappack",
  setTab: () => {},
});

export const useMapctn = () => useContext(MapContext);

export const MapContainer = (props: BoxProps) => {
  const [tab, setTab] = useState<tabKey>("mappack");

  return (
    <MapContext.Provider value={{ tab, setTab }}>
      <MainContainer signInOnly sidebar={<MainSidebar selected={tab} />}>
        <Box {...props}>
          <Container maxWidth="md">{props.children}</Container>
        </Box>
      </MainContainer>
    </MapContext.Provider>
  );
};
