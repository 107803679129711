import { ContentHeader } from "components/core-sub/ContentHeader";
import { useCore } from "components/core-sub/context";
import { Map } from "components/core-sub/Controller/map";
import { DataGrid } from "components/core-sub/DataGrid";
import { KuiActionIcon } from "components/core-sub/KuiActionIcon";
import { KuiButton } from "components/core-sub/KuiButton";
import { usePopup } from "components/core-sub/Popup";
import {
  VisibilityTabsValue,
  VisibilityTabs,
} from "components/core-sub/VisibilityTabs";
import { useMapctn } from "components/map.container";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const ViewMappack = () => {
  const { setTab: st } = useMapctn();
  const { user, t } = useCore();
  const { Popup } = usePopup();
  const nav = useNavigate();
  const [tab, setTab] = useState<VisibilityTabsValue>("public");
  const [maps, setMaps] = useState<{ loading: boolean; docs: Map[] }>({
    loading: true,
    docs: [],
  });

  useEffect(() => {
    st("mappack");
    if (user.loading === false && user.data) {
      return Map.watchMy(
        user.data,
        (docs) => setMaps((s) => ({ ...s, docs, loading: false })),
        ["mappack"]
      );
    } else {
      setMaps((s) => ({ ...s, docs: [], loading: true }));
    }
  }, [user, st]);

  const handleAdd = () => {
    Popup.prompt({
      title: t("Add $Name", { name: "MapPack" }),
      text: t("Title"),
      icon: "plus-circle",
      onConfirm: async (value) => {
        if (user.data && value) {
          const item = await Map.add(user.data, value, "mappack");
          Popup.confirm({
            title: t("Added"),
            text: t("Ready To Edit", { name: value }),
            icon: "check-circle",
            onConfirm: () => {
              nav(`/mappack/edit/${item.id}`);
            },
          });
        }
      },
    });
  };

  const handleTrash = (doc: Map) => () => {
    Popup.remove({
      title: t("Remove"),
      text: t("Do You Want To Remove $Name", { name: doc.title }),
      icon: "trash",
      onConfirm: async () => {
        await doc.update("visibility", "trash");
        Popup.alert({
          title: t("Removed"),
          text: t("$Name Has Removed", { name: doc.title }),
          icon: "check-circle",
        });
      },
    });
  };

  const handleRestore = (doc: Map) => async () =>
    await doc.update("visibility", "private");

  const handleRemove = (doc: Map) => () => {
    Popup.remove({
      title: t("Remove"),
      text: t("Do You Want To Remove $Name", { name: doc.title }),
      icon: "trash",
      onConfirm: async () => {
        await doc.remove();
        Popup.alert({
          title: t("Removed"),
          text: t("$Name Has Removed", { name: doc.title }),
          icon: "check-circle",
        });
      },
    });
  };

  return (
    <>
      <ContentHeader
        label="My MapPack"
        breadcrumbs={[{ label: t("Home") }, { label: "My MapPack" }]}
        actions={<KuiButton tx="add" onClick={handleAdd} />}
      />
      <VisibilityTabs value={tab} onChange={setTab} items={maps.docs} />
      <DataGrid
        loading={maps.loading}
        rows={maps.docs.filter((d) => d.visibility === tab)}
        columns={[
          {
            field: "action",
            headerName: " ",
            renderCell: ({ row }) =>
              row.visibility === "trash" ? (
                <>
                  <KuiActionIcon tx="restore" onClick={handleRestore(row)} />
                  <KuiActionIcon tx="remove" onClick={handleRemove(row)} />
                </>
              ) : (
                <>
                  <KuiActionIcon
                    tx="edit"
                    onClick={() => nav(`/mappack/edit/${row.id}`)}
                  />
                  <KuiActionIcon tx="remove" onClick={handleTrash(row)} />
                </>
              ),
            width: 64,
            align: "center",
            filterable: false,
            sortable: false,
          },
          { field: "title", headerName: t("Title"), width: 240 },
          {
            field: "datemodified",
            headerName: t("Date"),
            width: 240,
            valueFormatter: ({ value }) =>
              moment(value).format("DD/MM/YYYY LT"),
          },
        ]}
        disableSelectionOnClick
      />
    </>
  );
};
