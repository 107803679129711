import { Avatar, Link } from "@mui/material";
import { ContentHeader } from "components/core-sub/ContentHeader";
import { useCore } from "components/core-sub/context";
import { Map } from "components/core-sub/Controller/map";
import { DataGrid } from "components/core-sub/DataGrid";
import { DialogMaps } from "components/core-sub/DialogMaps";
import { KuiActionIcon } from "components/core-sub/KuiActionIcon";
import { KuiButton } from "components/core-sub/KuiButton";
import { MarkerCat } from "components/core-sub/Maps";
import { PickIcon } from "components/core-sub/PickIcon";
import { usePopup } from "components/core-sub/Popup";
import {
  VisibilityTabsValue,
  VisibilityTabs,
} from "components/core-sub/VisibilityTabs";
import { useMapctn } from "components/map.container";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const ViewMarker = () => {
  const { setTab: st } = useMapctn();
  const { user, t } = useCore();
  const [state, setState] = useState<{
    loading: boolean;
    docs: Map[];
    view: null | { name: string; docs: Map[] };
  }>({
    loading: true,
    docs: [],
    view: null,
  });
  const { Popup } = usePopup();
  const nav = useNavigate();
  const [tab, setTab] = useState<VisibilityTabsValue>("public");

  useEffect(() => {
    st("marker");
    if (user.loading === false && user.data) {
      return Map.watchMy(
        user.data,
        (docs) => setState((s) => ({ ...s, docs, loading: false })),
        ["marker"]
      );
    } else {
      setState((s) => ({ ...s, loading: true, docs: [] }));
    }
  }, [user, st]);

  const handleAdd = () => {
    Popup.prompt({
      title: t("Add $Name", { name: "Marker" }),
      text: t("Title"),
      icon: "plus-circle",
      onConfirm: async (value) => {
        if (user.data && value) {
          const item = await Map.add(user.data, value, "marker");
          Popup.confirm({
            title: t("Added"),
            text: t("Ready To Edit", { name: value }),
            icon: "check-circle",
            onConfirm: () => {
              nav(`/marker/edit/${item.id}`);
            },
          });
        }
      },
    });
  };

  const handleTrash = (doc: Map) => () => {
    Popup.remove({
      title: t("Remove"),
      text: t("Do You Want To Remove $Name", { name: doc.title }),
      icon: "trash",
      onConfirm: async () => {
        await doc.update("visibility", "trash");
        Popup.alert({
          title: t("Removed"),
          text: t("$Name Has Removed", { name: doc.title }),
          icon: "check-circle",
        });
      },
    });
  };

  const handleRemove = (doc: Map) => () => {
    Popup.remove({
      title: t("Remove"),
      text: t("Do You Want To Remove $Name", { name: doc.title }),
      icon: "trash",
      onConfirm: async () => {
        await doc.remove();
        Popup.alert({
          title: t("Removed"),
          text: t("$Name Has Removed", { name: doc.title }),
          icon: "check-circle",
        });
      },
    });
  };

  const handleRestore = (doc: Map) => () => doc.update("visibility", "private");

  const avatarIcon = (doc: Map) => {
    const icon = MarkerCat.find((i) => i.id === doc.cat);
    if (icon) {
      return (
        <Avatar style={{ backgroundColor: icon.color }}>
          <PickIcon icon={icon.icon} />
        </Avatar>
      );
    } else {
      return <Avatar children={<PickIcon icon={"question"} />} />;
    }
  };

  return (
    <>
      <ContentHeader
        label="My Marker"
        breadcrumbs={[{ label: t("Home") }, { label: "My Marker" }]}
        actions={<KuiButton tx="add" onClick={handleAdd} />}
      />
      <VisibilityTabs
        value={tab}
        onChange={setTab}
        count={{
          public: state.docs.filter((d) => d.visibility === "public").length,
          private: state.docs.filter((d) => d.visibility === "private").length,
          trash: state.docs.filter((d) => d.visibility === "trash").length,
        }}
      />
      <DataGrid
        loading={state.loading}
        rows={state.docs.filter((d) => d.visibility === tab)}
        columns={[
          {
            field: "action",
            headerName: " ",
            renderCell: ({ row }) =>
              row.visibility === "trash" ? (
                <>
                  <KuiActionIcon tx="restore" onClick={handleRestore(row)} />
                  <KuiActionIcon tx="remove" onClick={handleRemove(row)} />
                </>
              ) : (
                <>
                  <KuiActionIcon
                    tx="edit"
                    onClick={() => nav(`/marker/edit/${row.id}`)}
                  />
                  <KuiActionIcon tx="remove" onClick={handleTrash(row)} />
                </>
              ),
            width: 64,
            align: "center",
            filterable: false,
            sortable: false,
          },
          {
            field: "icon",
            headerName: " ",
            filterable: false,
            sortable: false,
            renderCell: ({ row }) => avatarIcon(row),
            width: 64,
            align: "center",
          },
          {
            field: "title",
            headerName: t("Title"),
            renderCell: ({ row }) => (
              <Link
                onClick={() =>
                  setState((s) => ({
                    ...s,
                    view: { name: row.title, docs: [row] },
                  }))
                }
                sx={{ cursor: "pointer" }}
              >
                {row.title}
              </Link>
            ),
            width: 240,
          },
          {
            field: "datemodified",
            headerName: t("Date"),
            width: 240,
            valueFormatter: ({ value }) => moment(value).format("L LT"),
          },
        ]}
        disableSelectionOnClick
      />
      <DialogMaps
        open={Boolean(state.view)}
        onClose={() => setState((s) => ({ ...s, view: null }))}
        icon="map-marker-alt"
        maps={state.view?.docs ?? []}
        title={state.view?.name}
      />
    </>
  );
};
