import { db } from "components/core-sub/Controller/firebase";
import { User } from "firebase/auth";
import {
  collection,
  doc,
  DocumentData,
  QueryDocumentSnapshot,
} from "firebase/firestore";

export class SkeletonController {
  prefix: string = `${process.env.REACT_APP_PREFIX}`;

  constructor(protected user: User) {}

  collection(path: string, ...pathSegments: string[]) {
    return collection(db, "clients", this.prefix, path, ...pathSegments);
  }

  doc(path: string, ...pathSegments: string[]) {
    return doc(db, "clients", this.prefix, path, ...pathSegments);
  }

  protected toDoc<T extends unknown>(
    doc: QueryDocumentSnapshot<DocumentData>
  ): T {
    return { ...doc.data(), id: doc.id } as T;
  }
}
