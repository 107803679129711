import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import {
  GridFilterModel,
  GridSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { MapController, MapDocument } from "controllers/mappack";
import moment from "moment";
import { ChangeEvent, useEffect, useState } from "react";
import {
  MarkerCatDict,
  RouteCatDict,
} from "components/core-sub/Maps/maps.icon";
import { useCore } from "components/core-sub/context";
import { KuiButton } from "components/core-sub/KuiButton";
import { PickIcon, PickIconName } from "components/core-sub/PickIcon";
import { DataGrid } from "components/core-sub/DataGrid";

export interface MappackSelectItemProps {
  selection?: string[];
  open: boolean;
  onClose?: () => void;
  onConfirm?: (ids: string[]) => void;
}

const getIcon = (doc: MapDocument): JSX.Element => {
  let icon: PickIconName = "question";
  let color: string | undefined = undefined;
  switch (doc.type) {
    case "marker":
      if (doc.cat && MarkerCatDict[doc.cat]) {
        icon = MarkerCatDict[doc.cat].icon;
        color = MarkerCatDict[doc.cat].color;
      }
      break;
    case "route":
      if (doc.cat && RouteCatDict[doc.cat]) {
        icon = RouteCatDict[doc.cat].icon;
        color = doc.color;
      }
      break;
    case "area":
      icon = "draw-polygon";
      color = doc.color;
      break;
  }
  return (
    <Avatar sx={{ backgroundColor: color }}>
      <PickIcon icon={icon} />
    </Avatar>
  );
};

export const MappackSelectItem = (props: MappackSelectItemProps) => {
  const { t, user } = useCore();
  const [state, setState] = useState<{ loading: boolean; docs: MapDocument[] }>(
    {
      loading: true,
      docs: [],
    }
  );
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "datemodified", sort: "desc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [selection, setSelection] = useState<GridSelectionModel>([]);

  useEffect(() => {
    if (user.loading === false && user.data) {
      if (props.open) {
        const control = new MapController(user.data);
        return control.watchAll<MapDocument>((docs) =>
          setState((s) => ({ ...s, loading: false, docs }))
        );
      } else {
        setState({ loading: true, docs: [] });
      }
    }
  }, [user, props.open]);

  useEffect(() => {
    if (props.selection && state.loading===false) {
      setSelection(props.selection);
    }
  }, [props.selection, state.loading]);

  const handleSearch = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(value);
    setFilterModel({
      items: [
        {
          id: 1,
          columnField: "title",
          operatorValue: "contains",
          value,
        },
      ],
    });
  };

  const handleConfirm = () => {
    props.onConfirm?.(selection as string[]);
    props.onClose?.();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="post"
      scroll="paper"
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle>Maps</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          size="small"
          value={searchValue}
          onChange={handleSearch}
          inputProps={{ placeholder: t("Search") }}
          sx={{ pb: 2 }}
        />
        <DataGrid
          loading={state.loading}
          rows={state.docs}
          columns={[
            {
              field: "avatar",
              headerName: " ",
              renderCell: ({ row }) => getIcon(row),
              width: 60,
              align: "center",
            },
            { field: "title", headerName: t("Title"), width: 240 },
            {
              field: "type",
              headerName: t("Type"),
              renderCell: ({ value }) => `${value}`.toLocaleUpperCase(),
            },
            {
              field: "datemodified",
              headerName: t("Date"),
              valueGetter: ({ value }) => value?.toMillis?.() || Date.now(),
              renderCell: ({ value }) => moment(value).format("L LT"),
              width: 180,
            },
          ]}
          checkboxSelection
          disableSelectionOnClick
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
          selectionModel={selection}
          onSelectionModelChange={setSelection}
        />
      </DialogContent>
      <DialogActions>
        <KuiButton tx="confirm" onClick={handleConfirm} />
        <KuiButton tx="close" onClick={props.onClose} />
      </DialogActions>
    </Dialog>
  );
};
