import { Box, ListItem, Stack, styled, TextField } from "@mui/material";
import { Marker, Polygon, Polyline } from "@react-google-maps/api";
import { useAlerts } from "components/core-sub/Alerts";
import { BackLink } from "components/core-sub/BackLink";
import { useCore } from "components/core-sub/context";
import { Map } from "components/core-sub/Controller/map";
import MainContainer from "components/core-sub/MainContainer";
import { GoogleMaps, getRouteIcon } from "components/core-sub/Maps";
import { SaveButton } from "components/core-sub/SaveButton";
import { TitleDebounce } from "components/core-sub/TitleDebounce";
import { VisibilitySelect } from "components/core-sub/Visibility";
import { useEffect, useState, ChangeEvent } from "react";
import update from "react-addons-update";
import { useParams } from "react-router-dom";
import { ButtonIcon } from "view/route.edit";

export interface stateTypes {
  loading: boolean;
  data: Map;
  restrict: boolean;
}

const MapRoot = styled(Box)(() => ({
  width: "100%",
  height: `calc(100vh - 65px)`,
}));

export const ViewAreaEdit = () => {
  const { id } = useParams();
  const { user, t } = useCore();
  const { addAlert } = useAlerts();
  const [state, setState] = useState<stateTypes>({
    loading: true,
    restrict: true,
    data: new Map({}),
  });
  const [store, setStore] = useState<{ select: number }>({
    select: -1,
  });

  const handleSave = async () => {
    state.data.save();
    addAlert({ label: t("Saved") });
  };
  const handleChangeTitle = (value: string) => {
    state.data.title = value;
    setState((s) => ({ ...s, data: state.data }));
  };
  const handleChangeColor = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    state.data.color = value;
    setState((s) => ({ ...s, data: state.data }));
  };
  const handleDragend = (index: number) => (e: google.maps.MapMouseEvent) => {
    const latLng = e?.latLng?.toJSON();
    if (latLng) {
      setState((s) => ({ ...s, data: s.data.setLatLngs(index, latLng) }));
    }
  };
  const handlePolygonClick = (e: google.maps.MapMouseEvent) => {
    const latLng = e?.latLng?.toJSON();
    if (latLng) {
      setState((s) => ({ ...s, data: s.data.appendLatLngs(latLng) }));
    }
  };
  const handleRemoveSelect = () => {
    if (store.select) {
      setState((s) => ({ ...s, data: s.data.removeLatLngs(store.select) }));
    }
    handleUnselect();
  };
  const handleReverse = () =>
    setState((s) => ({ ...s, data: s.data.reverseLatLngs() }));
  const handleRemoveLast = () => {
    setState((s) => ({ ...s, data: s.data.removeLastLatLngs() }));
  };
  const handleClosePath = () => {
    setState((s) => ({ ...s, data: s.data.closePathLatLngs() }));
  };
  const handleUnselect = () => setStore((s) => ({ ...s, select: -1 }));
  const handleLineClick = (index: any) => (e: google.maps.MapMouseEvent) => {
    const latLng = e.latLng?.toJSON();
    if (latLng) {
      setState((s) => ({ ...s, data: s.data.insertLatLngs(index, latLng) }));
    }
    handleUnselect();
  };
  const handleMarkerClick =
    (index: number) => (_e: google.maps.MapMouseEvent) => {
      setStore((s) => update(s, { select: { $set: index } }));
    };
  const handleMapClick = (e: google.maps.MapMouseEvent) => {
    const latLng = e.latLng?.toJSON();
    if (latLng) {
      setState((s) => ({ ...s, data: s.data.appendLatLngs(latLng) }));
    }
  };

  useEffect(() => {
    if (user.loading === false && user.data && id) {
      Map.getOne(id).then((data) => {
        if (data) {
          setState((s) => ({ ...s, loading: false, data, restrict: false }));
        } else {
          setState((s) => ({
            ...s,
            loading: false,
            data: new Map(),
            restrict: true,
          }));
        }
      });
    } else {
      setState({
        loading: false,
        restrict: true,
        data: new Map(),
      });
    }
  }, [user, id]);

  return (
    <MainContainer
      dense
      signInOnly
      restrict={state.restrict}
      sidebar={
        <>
          {" "}
          <BackLink divider to={`/area`} />
          <SaveButton onSave={handleSave} />
          <TitleDebounce
            value={state.data.title}
            onChange={handleChangeTitle}
          />
          <VisibilitySelect
            value={state.data.visibility}
            onChange={(value) => {
              state.data.visibility = value;
              setState((s) => ({ ...s, data: state.data }));
            }}
          />
          <ListItem divider>
            <Stack spacing={2} sx={{ py: 1, flex: 1 }}>
              <TextField
                fullWidth
                type="color"
                label="Color"
                value={state.data.color}
                onChange={handleChangeColor}
              />
              <ButtonIcon icon="exchange-alt" onClick={handleReverse}>
                {t("Reverse")}
              </ButtonIcon>
              {state.data.canClosePathLatLngs() && (
                <ButtonIcon icon={"sync-alt"} onClick={handleClosePath}>
                  {t("Close Path")}
                </ButtonIcon>
              )}
              <ButtonIcon
                color="error"
                icon={"trash"}
                onClick={handleRemoveLast}
              >
                {t("Remove Last Point")}
              </ButtonIcon>
            </Stack>
          </ListItem>
          {store.select > -1 && (
            <ListItem divider>
              <Stack sx={{ flex: 1 }} spacing={1}>
                <ButtonIcon
                  icon="trash"
                  color="error"
                  onClick={handleRemoveSelect}
                >
                  POSITION #{store.select + 1}
                </ButtonIcon>
                <ButtonIcon icon="xmark" onClick={handleUnselect}>
                  {t("Deselect")}
                </ButtonIcon>
              </Stack>
            </ListItem>
          )}
        </>
      }
    >
      <MapRoot>
        <GoogleMaps onClick={handleMapClick}>
          {state.data.latLngs?.map((latLng, index) => (
            <Marker
              key={index}
              position={latLng}
              draggable={true}
              onDragEnd={handleDragend(index)}
              onClick={handleMarkerClick(index)}
              icon={getRouteIcon(
                state.data.color,
                index === 0 ? "medium" : "small"
              )}
            />
          ))}
          {(state.data.latLngs?.length ?? 0) > 1 &&
            state.data.latLngs?.map((latLng, index, latLngs) => {
              return index > 0 ? (
                <Polyline
                  path={[latLng, latLngs[index - 1]]}
                  key={index}
                  options={{
                    strokeColor: state.data.color,
                    strokeOpacity: 1,
                    strokeWeight: 4,
                  }}
                  onClick={handleLineClick(index)}
                />
              ) : null;
            })}

          {(state.data.latLngs?.length ?? 0) > 2 && (
            <Polygon
              path={state.data.latLngs}
              key={`polygon-${state.data.color}`}
              options={{
                strokeWeight: 0,
                fillColor: state.data.color,
                fillOpacity: 0.4,
              }}
              onClick={handlePolygonClick}
            />
          )}
        </GoogleMaps>
      </MapRoot>
    </MainContainer>
  );
};
