import { PickIconName } from "components/core-sub/PickIcon";

export const icons: { id: string; label: string; icon: PickIconName }[] = [
  { id: "train", label: "สายรถไฟ/ราง/กระเช้า", icon: "subway" },
  { id: "bus", label: "สายรถบัส", icon: "bus" },
  { id: "car", label: "เส้นทางแท็กซี่/รถ", icon: "car" },
  { id: "bicycle", label: "เส้นทางจักรยาน", icon: "bicycle" },
  { id: "ship", label: "เส้นทางเรือ", icon: "ship" },
  { id: "walk", label: "เส้นทางเดิน", icon: "walking" },
];

const iconSVG = (color: string) => `<?xml version="1.0" encoding="utf-8"?>
  <!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
  <g>
      <path opacity="0.5" fill="${
        color || "#FF960B"
      }" enable-background="new    " d="M12,0C5.373,0,0,5.373,0,12c0,6.627,5.373,12,12,12
          c6.627,0,12-5.373,12-12C24,5.373,18.627,0,12,0z M12,18.961c-3.838,0-6.961-3.124-6.961-6.961S8.162,5.04,12,5.04
          s6.961,3.122,6.961,6.96S15.838,18.961,12,18.961z"/>
      <circle fill="${color || "#FF960B"}" cx="12" cy="12" r="4.96"/>
      <path fill="#FFFFFF" d="M12,5.04c-3.838,0-6.961,3.123-6.961,6.96S8.162,18.961,12,18.961s6.961-3.123,6.961-6.961
          S15.838,5.04,12,5.04z M12,16.961c-2.74,0-4.961-2.222-4.961-4.961c0-2.74,2.221-4.96,4.961-4.96c2.74,0,4.961,2.221,4.961,4.96
          S14.74,16.961,12,16.961z"/>
  </g>
  </svg>`;
/**
 *
 * @param {string} color - Route color
 * @returns
 */
export const getRouteIcon = (
  color: string,
  _size?: "small" | "medium" | "large"
): any => ({
  url: `data:image/svg+xml;charset=UTF-8;base64,${btoa(iconSVG(color))}`,
  scaledSize: { width: 20, height: 20, f: "px", b: "px" },
  labelOrigin: { x: 10, y: 10 },
  anchor: { x: 10, y: 10 },
});
