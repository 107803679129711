import { Box, ListItem, SelectChangeEvent, styled } from "@mui/material";
import { Marker } from "@react-google-maps/api";
import { useAlerts } from "components/core-sub/Alerts";
import { BackLink } from "components/core-sub/BackLink";
import { useCore } from "components/core-sub/context";
import { Map } from "components/core-sub/Controller/map";
import MainContainer from "components/core-sub/MainContainer";
import {
  getMarkerIcon,
  GoogleMaps,
  MarkerCatType,
} from "components/core-sub/Maps";
import { SaveButton } from "components/core-sub/SaveButton";
import { TitleDebounce } from "components/core-sub/TitleDebounce";
import { VisibilitySelect } from "components/core-sub/Visibility";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MarkerCatSelect } from "./select.cat";

const MapRoot = styled(Box)(() => ({
  width: "100%",
  height: `calc(100vh - 65px)`,
}));

export const ViewMarkerEdit = () => {
  const { user, t } = useCore();
  const { id } = useParams();
  const { addAlert } = useAlerts();
  const [state, setState] = useState<{
    loading: boolean;
    restrict: boolean;
    data: Map | null;
    init: boolean;
    map?: google.maps.Map;
  }>({
    loading: true,
    restrict: true,
    data: null,
    init: false,
  });

  useEffect(() => {
    if (user.loading === false && id) {
      if (user.data) {
        Map.getOne(id).then((data) =>
          setState((s) => ({ ...s, data, loading: false, restrict: false }))
        );
      } else {
        setState((s) => ({ ...s, loading: false, restrict: true, data: null }));
      }
    }
  }, [id, user]);

  useEffect(() => {
    if (state.init === false) {
      setTimeout(() => {
        if(state.map && state.data?.latLng){
          state.map.setCenter(state.data.latLng)
          state.map.setZoom(10)
          setState((s) => ({ ...s, init: true }));
        }
      }, 500)
    }
  }, [state.init, state.map, state.data?.latLng]);

  const handleChangeEvent =
    <T extends keyof Map>(key: T) =>
    ({ target: { value } }: SelectChangeEvent<unknown>) =>
      setState((s) => ({
        ...s,
        data: s.data
          ? s.data?.set(key, value as Map[T])
          : new Map({ [key]: value }),
      }));

  const handleChangeTitle = (title: string) =>
    setState((s) => ({ ...s, data: s.data?.set("title", title) ?? null }));

  const handleOnClickMap = (e: google.maps.MapMouseEvent) => {
    const latLng = e.latLng?.toJSON();
    if (latLng) {
      setState((s) => ({
        ...s,
        data: s.data ? s.data?.set("latLng", latLng) : new Map({ latLng }),
      }));
    }
  };
  const handleDragEnd = (e:google.maps.MapMouseEvent) => {
    const latLng = e.latLng?.toJSON()
    if(latLng){
      setState((s) => ({
        ...s,
        data: s.data ? s.data?.set("latLng", latLng) : new Map({ latLng }),
      }));
    }
  }

  const handleSave = async () => {
    await state.data?.save();
    addAlert({ label: t("Saved") });
  };

  return (
    <MainContainer
      loading={state.loading}
      restrict={state.restrict}
      dense
      signInOnly
      sidebar={
        state.restrict === false && (
          <>
            <BackLink to="/marker" divider />
            <SaveButton onSave={handleSave} />
            <TitleDebounce
              value={state.data?.title}
              onChange={handleChangeTitle}
            />
            <VisibilitySelect
              value={state.data?.visibility}
              onChange={(value) =>
                setState((s) => ({
                  ...s,
                  data: s.data?.set("visibility", value) ?? null,
                }))
              }
            />
            <ListItem divider>
              <MarkerCatSelect
                value={state?.data?.cat || ""}
                onChange={handleChangeEvent("cat")}
                selectProps={{ fullWidth: true }}
              />
            </ListItem>
          </>
        )
      }
    >
      <MapRoot>
        <GoogleMaps
          onClick={handleOnClickMap}
          onLoad={(map) => setState((s) => ({ ...s, map }))}
        >
          {state.data?.latLng && (
            <Marker
              position={state.data.latLng}
              icon={getMarkerIcon(state.data.cat as MarkerCatType)}
              draggable={true}
              onDragEnd={handleDragEnd}
            />
          )}
        </GoogleMaps>
      </MapRoot>
    </MainContainer>
  );
};
