import { Box, MenuItem, Select, SelectProps } from "@mui/material";
import { PickIcon } from "components/core-sub/PickIcon";
import { icons } from "./icons";

export interface RouteCatSelectProps {
  value: SelectProps["value"];
  onChange: SelectProps["onChange"];
  selectProps?: Omit<SelectProps, "value" | "onChange">;
}

export const RouteCatSelect = ({ value, onChange, selectProps }: RouteCatSelectProps) => {
  return (
    <Select
      fullWidth
      displayEmpty
      variant="outlined"
      value={value}
      onChange={onChange}
      {...selectProps}
    >
      <MenuItem value="">-- เลือกประเภท --</MenuItem>
      {icons.map((icon) => (
        <MenuItem key={icon.id} value={icon.id}>
          <Box display="inline-block" style={{ width: 36 }}>
            <PickIcon icon={icon.icon} />
          </Box>
          {icon.label}
        </MenuItem>
      ))}
    </Select>
  );
};
